<template>
  <v-app id="inspire">
    <div
      v-if="!onLine"
      class="offline"
    >
      BRAK POŁĄCZENIA Z INTERNETEM
    </div>
    <transition
      name="fade"
      mode="out-in"
      appear
    >
      <router-view />
    </transition>
    <vue-snotify />
  </v-app>
</template>

<script>
export default {
  name: '',
  data: () => ({
    onLine: navigator.onLine,
  }),
  mounted() {
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
  },
  beforeDestroy() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  },
  methods: {
    updateOnlineStatus() {
      this.onLine = navigator.onLine;
      if (navigator.onLine) {
        this.$snotify.success('Przywrócono połączenie z internetem!');
        return;
      }
      this.$snotify.error('Straciłeś połączenie z internetem!');
    },
  },
};
</script>

<style lang="scss">
.offline {
  position: fixed;
  z-index: 1000;
  width: 100%;
  top: 0;
  background: #e53935;
  text-align: center;
  color: #ffffff;
  font-size: 14px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
